<template>
  <div class="home-app">
    <!-- page header -->
    <nav class="navbar navbar-expand-lg navbar-light" 
      style="font-size: large;display: flex;position: fixed;width: 100%;z-index: 1000;
        background-color: whitesmoke; padding: 1rem 3%;box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img width="24" height="24" src="../assets/app_logo.svg">
          <span style="font-size: x-large; color: green; margin-left: 0.8rem;font-weight: bold;">Guva</span>
        </a>

        <button class="navbar-toggler collapsed" 
          type="button" data-bs-toggle="collapse" 
          data-bs-target="#navbarNavAltMarkup" 
          aria-controls="navbarNavAltMarkup" 
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          <span class="my-1 mx-2 close">X</span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" 
            id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
            <a class="nav-link" href="#features">Features</a>
            <a class="nav-link" href="#contact">Contact Us</a>
            <a class="nav-link" href="#about">About</a>
          </div>
        </div>
        
      </div>
    </nav>

    <!-- page landing content -->
    <section class="home" id="home">

      <div class="content">
        <h3>Digitizing the farmers market<br/>and food industry</h3><br>
        <p>We create a large community of farmers, producers, retailers and consummers
          to easily link the process of food value chain.<br>
          Focus on production and let the right tools manage<br>your growth and expansion.
        </p>
        <a class="btn" href="https://play.google.com/store/apps/details?id=com.casontek.farmconnect">
          <i class="fa-brands fa-google-play"></i>
          Download App
        </a>
        <div class="alert alert-info alert-dismissible fade show" role="alert"
          style="width: 240px;margin-top: 0.5rem; margin-left: 1rem;" v-show="isDownloadAlert">
          check back in few days later!
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>

      <div class="image">
        <img src="../assets/images/shopping-phone-img.png" alt="description banner">
      </div>

    </section>

    <!-- features of the app -->
    <section class="features" id="features">

      <h1 class="heading">Features</h1>

      <div class="box-container">

        <div class="box">
          <img src="../assets/images/farmconnect-sales-img.jpg" alt="Buying and Salling">
          <h3>Online Buying & Selling</h3>
          <p>An amazing patform for Farmers and producers to buy and sale their products.<br/>
          Our online market help both small and large business owners, meet and locate their target custommers.</p>
          <!--<a href="#" class="btn">Read more</a> -->
        </div>

        <div class="box">
          <img src="../assets/images/farmconnect-profile-img.jpg" alt="Business profile">
          <h3>Engaging Business Profile</h3>
          <p>Build an astoning farm or market profile page, with the stories and pictures of your products,
            farms, stores, plants etc.<br>Profile page helps you connect and communicate with your partners and custommers.
          </p>
        </div>

        <div class="box">
          <img src="../assets/images/farmconnect-marketing-img.png" alt="Business Advertisment">
          <h3>Marketing & Advertisment</h3>
          <p>Get your products and services projected to the right people.<br>
          Easily use our marketing tools to boost your business activities.</p>
        </div>

        <div class="box">
          <img src="../assets/images/farmconnect-inventory-img.jpg" alt="farmconnect feature">
          <h3>Inventory System</h3>
          <p>Track the progress of your business by using our inventory record system.<br>
          Take down every details of your business transactions, record sales and purchases as you progress.</p>
          
        </div>


        <div class="box">
          <img src="../assets/images/farmconnect-supports-img.jpg" alt="Business Partnership">
          <h3>Partnership & Support</h3>
          <p>We build and maintain connections with trusted farmers, producers and retailers.<br>
          our partners get first class support and services from our teams and agents.</p>
        </div>

      </div>
    </section>

    <section class="contact" id="contact">

      <div class="image">
        <img src="../assets/images/farmconnect-contact-img.jpg" alt="">
      </div>

      <form id="contactfarm" @submit="submitMessage" ref="formdata">

        <h1 class="heading">Contact Us</h1>

        <div class="inputBox">
          <input type="text" name="name" id="name" v-model="name" required>
          <label for="name">name</label>
        </div>

        <div class="inputBox">
          <input type="number" name="phone" id="phone" v-model="phone" required>
          <label for="phone">phone</label>
        </div>

        <div class="inputBox">
          <textarea name="message" id="message" v-model="message" cols="30" rows="10" required>
          </textarea>
          <label for="message">message</label>
          <span style="color: green; float: right;" v-show="messageStatus">message sent!</span>
        </div>

        <input type="submit" class="btn" v-model="sendText">

      </form>

    </section>

    <!-- About section of the app -->
    <section class="about" id="about">

      <h1 class="heading">About</h1>

      <div class="column">

        <div class="image">
          <img src="../assets/images/farmconnect-about-img.jpg" alt="About Us">
        </div>

        <div class="content">
          <h3>our mission and goal</h3>
          <p>The foundamental goal is to support and innovate, the agricultural business activities,to increase food production.</p>
          <p>Our mission is to help boost food production and availability in Africa,<br>
            to provide technical and technological support and services to small and large scale farmers, producers and retailers.<br>
            We are committed to making sure that our clients, and their custommers gets the best service and support.
          </p>

          <div class="buttons">
            <a class="btn" @click="iosAppDownloadAlert">
              <!--<img src="../assets/apple-brands.svg" width="24" height="24"> -->
              <i class="fa-brands fa-apple"></i>
              app store
            </a>
            <a class="btn" href="https://play.google.com/store/apps/details?id=com.casontek.farmconnect">
              <!--<img src="../assets/google-play-brands.svg" width="24" height="24"> -->
              <i class="fa-brands fa-google-play"></i>
               play store
            </a>
          </div>
          <div class="alert alert-info alert-dismissible fade show" role="alert"
              style="width: 240px;margin-top: 0.6rem; margin-left: 1rem;" v-show="isAndroidAlert">
            Android App ready, check back in few hours!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
          </div>
          <div class="alert alert-info alert-dismissible fade show" role="alert"
              style="width: 240px;margin-top: 0.6rem; margin-left: 1rem;" v-show="isiOSAlert">
            iOS App not ready. check back in few days!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </section>

    <div class="footer">
      <div class="box-container">

        <div class="box">
          <h3>about us</h3>
          <span style="font-size: small;">Guva Networks, was established with the sole purpose of<br> building communication line among farmers,
            <br>producers and distributors of agro-related contents.
            </span>
        </div>

        <div class="box">
          <h3>quick links</h3>
          <a href="#">home</a>
          <a href="#features">features</a>
          <a href="#about">about</a>
          <a href="#contact">contact</a>
        </div>

        <div class="box">
          <h3>follow us</h3>
          <a href="https://m.facebook.com/Guva-102184585066308/">
            <img src="../assets/square-facebook-brands.svg" width="24" height="24">
            facebook
          </a>
          <a href="https://www.twitter.com/guva">
            <img src="../assets/square-twitter-brands.svg" width="24" height="24">
            twitter
          </a>
          <a href="https://www.linkedin.com/company/guva-app">
            <img src="../assets/linkedin-brands.svg" width="24" height="24" style="color: white;">
            linkedIn
          </a>
          <a href="https://wa.me/message/QOKKGF5TRKIMJ1">
            <img src="../assets/whatsapp.svg" width="24" height="24" style="color: white;">
            WhatsApp
          </a>
        </div>

        <div class="box">
          <h3>contact info</h3>
          <div class="info">
            <img src="../assets/email_ico.png" width="24" height="24">
            <p><span><br>&nbsp;support@guva.com.ng <br>&nbsp;casontek@gmail.com</span></p>
          </div>
        </div>

        <div class="box">
          <h3>office location</h3>
          <div class="info">
            <img src="../assets/earth-africa-solid.svg" width="24" height="24">
            <p><span>&nbsp;Enugu, Nigeria</span></p>
          </div>
        </div>

      </div>
      <h1 class="credit"> &copy; copyright @2022 | Casontek</h1>
    </div>

  </div>
</template>

<script>
import AppVue from '@/App.vue';

export default {
  name: 'HomeView',
  data(){
    return {
      isDownloadAlert: false,
      isAndroidAlert: false,
      isiOSAlert: false,
      messageStatus: false,
      sendText: 'send message',
      name: '',
      message: '',
      phone: ''
    }
  },
  methods: {
    downloadGeneralAlert() {
      this.isDownloadAlert = true;
      setTimeout(() => this.isDownloadAlert = false, 2000);
      console.log('app not ready yet.');
    },

    iosAppDownloadAlert() {
      this.isiOSAlert = true;
      setTimeout(() => this.isiOSAlert = false, 2000);
    },

    androidAppDownloadAlert() {
      this.isAndroidAlert = true;
      setTimeout(() => this.isAndroidAlert = false, 2000);
    },

    async submitMessage(e) {
      //e.preventDefault();
      var n = this.name;
      var p = this.phone;
      var m = this.message;
      this.name = '';
      this.phone = '';
      this.message = '';
      this.messageStatus = true;
      /*
      const requestOption = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        id: 0,
        name: n,
        phone: p,
        message: m,
        receivedDate: new Date().toLocaleDateString()
      })
    }; */

    const response = await fetch('http://localhost:8084//api/v2/farm-connect/visitor/contact-us?name=' + n + 
          '&phone=' + p + '&message=' + m);
    const data = await response.json();
    console.log("response data: " + data, 1);

     
    }

  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,900;1,300;1,400;1,700;1,900&family=Poppins:wght@100;200;300;400&display=swap');

  .navbar-toggler>.close{
    display: inline;
  }
  .navbar-toggler.collapsed>.close, .navbar-toggler:not(.collapsed)>.navbar-toggler-icon{
    display: none;
  }

  *{
    --purple: #814096;
    --pink: #F83292;
    --gradient:linear-gradient(90deg, #814096, #F83292);
  }

  *{
    font-family: 'Poppins', sans-serif;
    margin: 0; padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    border: none;
    text-transform: capitalize;
  }

  *::selection{
    background: #814096;
    color: #fff;
  }

  section{
    min-height: 100vh;
    padding: 0 9%;
    padding-top: 7.5rem;
  }

  .btn{
    display: inline-block;
    margin-top: 1rem;
    padding: .8rem 3rem;
    border-radius: 5rem;
    background:var(--gradient);
    font-size: 1.7rem;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    transform: all .3s linear;
  }

  .heading{
    text-align: center;
    background: var(--gradient);
    color: #1c8003;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 2.8rem;
    text-transform: uppercase;
    padding: 1rem;
    font-family: "Roboto", sans-serif;
  }

  .btn:hover{
    transform: scale(1.04);
  }

  .home .image img{
    width: 25vw;
    animation: float 3s linear infinite;
  }

  .home{
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../assets/covers/background_img_3b.png) no-repeat;
    background-size: auto;
    background-position: right;
  }

  @keyframes float{
    0%, 100%{
      transform: translateY(0rem);
    }
    50%{
      transform: translateY(-3.5rem);
    }
  }

  .home .content h3{
    font-size: 3.1rem;
    margin-top: 2rem;
    color: rgb(2, 73, 2);
    text-transform: uppercase;
    font-family: 'Merriweather', serif;
    font-weight: bolder;
  }

  .home .content h3 span{
    color:var(--pink);
    text-transform: uppercase;
  }

  .fa::before{
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f09a";
  }

  .home .content p{
    font-size: 1.72rem;
    color: rgb(10, 10, 10);
    font-family: 'Merriweather', serif;
    padding: 0.1rem 0;
  }

  .about .column .content .buttons a:last-child{
    margin-left: 2rem;
  }

  .about .column .image{
    flex: 1 1 30rem;
  }

  @media (max-width:991px){
    section{
      padding: 0 3%;
      padding-top: 7.5rem;
    }

    .home{
        flex-flow: column-reverse;
        background-size: cover;
        background-position: right;
        background-image: url(../assets/covers/background_small_img_3b.png) no-repeat;
        padding-bottom: 5rem;
      }

    .home .image img{
      width: 30vw;
      animation: float 3s linear infinite;
    }

    .home .content h3{
      margin-top: 0.8rem;
    }
  }

  @media (max-width:768px){

      .home{
        flex-flow: column-reverse;
        background-size: cover;
        background-position: right;
        background-image: url(../assets/covers/background_small_img_3b.png) no-repeat;
        padding-bottom: 5rem;
      }

      .home .image img{
        display: none;
      }

      .home .content h3{
        font-size: 3.2rem;
        margin-top: 16rem;
      }

      .home .content p{
        font-size: 1.3rem;
      }

      .about{
        background-position: right;
      }

      .nav-link{
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .about .column .content .buttons a{
      width: 100%;
      text-align: center;
    }

    .about .column .content .buttons a:last-child{
      margin: 1.2rem 0rem;
    }

    .about .column .image img{
      margin-left: 3rem;
      padding-bottom: 1rem;
  }

  }


  @media (max-width:450px){

    html{
      font-size: 50%;
    }

    .about .column .content .buttons a{
      width: 100%;
      text-align: center;
    }

    .about .column .content .buttons a:last-child{
      margin: 1.2rem 0rem;
    }

    .home .image img{
      width: 45vw;
    }

    .home .content h3{
        font-size: 3.2rem;
        margin-top: 16rem;
      }

      .home .content p{
        font-size: 1.23rem;
      }

      .home{
        flex-flow: column-reverse;
        background-size: cover;
        background-position: right;
        background-image: url(../assets/covers/background_small_img_3b.png) no-repeat;
        padding-bottom: 5rem;
      }

  }


  .features .box-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .features .box-container .box{
    flex: 1 1 25rem;
    background: white;
    border-radius: .5rem;
    border: .1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    margin: 1.5rem;
    padding: 3rem 2rem;
    border-radius: .5rem;
    text-align: center;
    transition: .2s linear;
    max-width: 360px;
  }

  .features .box-container .box img{
    height: 15rem;
  }

  .features .box-container .box h3{
    font-size: 2rem;
    color: #333;
    padding-top: 1rem;
  }

  .features .box-container .box p{
    font-size: 1 3rem;
    color: #666;
    padding: 1rem 0;
  }

  .about{
    /*background: url(../assets/images/farmconnect-about-img.jpg) no-repeat; */
    background-size: cover;
    background-position: center;
    padding-bottom: 3rem;
  }

  .about .column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .about .column .image img{
    width: 85%;
  }

  .about .column .content{
    flex: 1 1 40rem;
  }

  .about .column .content h3{
    font-size: 3rem;
    color: #666;
  }

  .about .column .content p{
    font-size: 1.5rem;
    color: #666;
    padding: 1rem 0;
  }

  .contact{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 4rem;
  }

  .contact .image{
    flex:1 1 40rem;
  }

  .contact .image img{
    width: 90%;
    padding: 2rem;
  }

  .contact form{
    flex:1 1 40rem;
    border-radius: .5rem;
    border: .1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    padding: 2rem 3rem;
    background: #fff;
  }

  .contact form .heading{
    text-align: left;
    padding: 0;
    padding-bottom: 2rem;
  }

  .contact form .inputBox{
    position: relative;
    margin-top: 1rem;
  }

  .contact form .inputBox input, .contact form .inputBox textarea{
    width: 100%;
    background: none;
    color: #666;
    margin: .5rem 0;
    font-size: 1.7rem;
    border-bottom: .1rem solid rgba(0, 0, 0, .1);
    text-transform: none;
  }

  .contact form .inputBox textarea{
    resize: none;
    height: 13rem;
  }

  .contact form .inputBox label{
    position: absolute;
    top: 0.2rem; left: 0;
    font-size: 1.5rem;
    color: #666;
    transition: .2s linear;
  }

  .contact form .inputBox input:focus ~ label,
  .contact form .inputBox input:valid ~ label,
  .contact form .inputBox textarea:focus ~ label,
  .contact form .inputBox textarea:valid ~ label{
    top: -1.3rem;
    font-size: 1.3rem;
    color: var(--pink);
  }


  .footer{
    padding-top: 1rem;
    background: url(../assets/images/farmconnect-footer-img.png) no-repeat;
    background-size: cover;
    background-position: center;
  }

  .footer .box-container{
    display: flex;
    flex-wrap: wrap;
  }

  .footer .box-container .box{
    display:1 1 25rem;
    margin: 2rem;
  }

  .footer .box-container .box h3{
    font-size: 2.5rem;
    padding: 1rem 0;
    color: #b4865e;
    text-underline-offset: 1rem;
  }

  .footer .box-container .box p{
    font-size: 1.5rem;
    padding: .5rem 0;
    color: #eee;
  }

  .footer .box-container .box span{
    font-size: 1.4rem;
    padding: .3rem 0;
    color: #eee;
  }

  .footer .box-container .box a{
    display: block;
    font-size: 1.5rem;
    padding: .5rem 0;
    color: #eee;
  }

  .footer .box-container .box a:hover{
    text-decoration: underline;
  }

  .footer .box-container .box .info{
    display: flex;
    align-items: center;
  }

  .footer .box-container .box .info i{
    margin: .5rem 0;
    margin-right: 1rem;
    border-radius: 50%;
    background: white;
    color: var(--pink);
    font-size: 1.5rem;
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    text-align: center;
  }

  .footer .credit{
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: .1rem;
    color: white;
    border-top: .1rem solid #fff5;
    padding: 2.5rem 1rem;
    text-align: center;
  }

  .material-icons{
    font-family: 'Material Icons';
  }

</style>

